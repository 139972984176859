<script setup>
import anime from 'animejs/lib/anime.es.js';
import { onMounted } from 'vue';

onMounted(()=>{
  setTimeout(() => {
    titleMount()
  }, 500)
})


function titleMount(){
  anime({
    targets: '#main_title',
    opacity: 1,
    duration: 1000,
    easing: 'linear',
    complete: function() {
      mountedAnimation()
    }
  })
}

function mountedAnimation(){
  anime({
    targets: '#content-child',
    opacity: 1,
    translateY: [-10, 0],
    easing: 'linear',
    duration: 950,
    delay: anime.stagger(500)
  })
}

</script>
<template>
  <div class="body-content z-10" v-auto-animate>
    <div class="page-wrapper">
      <div class="content-wrapper text-center">
        <div id="main_title" class="opacity-0">
          <h1 class="text-2xl playfair font-semibold">
            <span class="italic">Mahamad Shabudin bin Baharum</span>
            <br>
            <span>&</span>
            <br>
            <span class="italic">Zarina bt Ahmad</span>
          </h1>
        </div>
  
        <div id="content-child" class="font-semibold tracking-wide mt-2">
          <p>Dengan segala hormatnya kami menjemput dan mempersilakan Tuan/Puan/Encik/Cik ke majlis perkahwinan puteri tunggal kami</p>
        </div>
  
        <div id="content-child" class="my-4">
          <div class="playfair !italic font-semibold text-3xl">
            <p>Nordina Syamira</p>
            <p>&</p>
            <p>Muhammad Izzat Zulkifli</p>
          </div>
        </div>
  
        <div id="content-child" class="mt-6">
          <router-link :to="{name: 'info'}"
            class="btn btn-primary font-semibold tracking-wide"
          >
            Info Majlis
          </router-link>
        </div>
        
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-wrapper{
  @apply  h-[85vh] overflow-scroll flex items-center;
}
</style>