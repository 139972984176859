<script setup>
import { onBeforeUnmount, onMounted } from 'vue';

var countDownDate = new Date("Nov 2, 2024 16:00:00").getTime();
onMounted(()=>{
  calculateTime
})

onBeforeUnmount(()=>{
  clearInterval(calculateTime);
})

const calculateTime = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

  // Display the result in the element with id="demo"
  document.getElementById("day").innerHTML = days;
  document.getElementById("hour").innerHTML = hours;
  document.getElementById("minute").innerHTML = minutes;

  // If the count down is finished, write some text
  if (distance < 0) {
    clearInterval(calculateTime);
    document.getElementById("expired").style.display = "block";
    document.getElementById("timer").style.display = "none";
  }
}, 1000);
</script>

<template>
  <div id="content-child" class="">
    <div id="timer">
      <p class="font-semibold mb-2">Masa berbaki </p>
      <div class="flex justify-center">
        <div>
          <div id="day" class="date-card day">
            999
          </div>
          <p class="font-semibold">Hari</p>
        </div>
        <div class="mx-2">
          <div id="hour" class="date-card hour">
            24
          </div>
          <p class="font-semibold">Jam</p>
        </div>
        <div>
          <div id="minute" class="date-card minute">
            60
          </div>
          <p class="font-semibold">Minit</p>
        </div>
      </div>
    </div>

    <div id="expired" class="hidden">
      <div class="date-card">
        Majlis sudah berlalu
      </div>
    </div>
  </div>
</template>

