<script setup>

</script>

<template>
  <audio id="audioEl">
    <source src="@/assets/song.mp3" type="audio/ogg">
    <source src="@/assets/song.mp3" type="audio/mpeg">
    Your browser does not support the audio element.
  </audio>
</template>

