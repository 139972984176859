<script setup>
import anime from 'animejs/lib/anime.es.js';
import { onMounted } from 'vue';
import InfoCard from './InfoCard.vue'

onMounted(()=>{
  setTimeout(() => {
    titleMount()
  }, 500)
})


function titleMount(){
  anime({
    targets: '#main_title',
    opacity: 1,
    duration: 1000,
    easing: 'linear',
    complete: function() {
      mountedAnimation()
    }
  })
}

function mountedAnimation(){
  anime({
    targets: '#content-child',
    opacity: 1,
    translateY: [-10, 0],
    easing: 'linear',
    duration: 950,
    delay: anime.stagger(500)
  })
}

</script>
<template>
  <div class="body-content z-10" v-auto-animate>
    <div class="page-wrapper">
      <div class="content-wrapper text-center">
        <div id="main_title" class="opacity-0 mb-4">
          <h1 class="text-3xl playfair font-semibold">
            Info Majlis
          </h1>
        </div>
  
        
        <InfoCard />
  
        <div id="content-child" class="mt-6 border border-dashed border-orange-950 py-3 px-4 rounded-lg">
          <p class="font-semibold">
            Kehadiran anda & keluarga amatlah kami hargai
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-wrapper{
  @apply  h-[85vh] overflow-scroll flex items-center;
}
</style>