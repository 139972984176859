<script setup>
import { onMounted} from 'vue';
import { useRouter } from 'vue-router';
import anime from 'animejs/lib/anime.es.js';

onMounted(()=>{
  setTimeout(() => {
    animateShit()
  }, 500)
})

function animateShit(){
  anime({
    targets: '#main_title',
    opacity: 1,
    duration: 1000,
    easing: 'linear',
    complete: function() {
      contentDrop()
    }
  })
}

function contentDrop(){
  anime({
    targets: '#content-child',
    opacity: 1,
    translateY: [-10, 0],
    easing: 'linear',
    duration: 950,
    delay: anime.stagger(700)
  })
}

const router = useRouter()
function playSong(){
  let aud = document.getElementById('audioEl')
  anime({
    targets: '.audBtn',
    opacity: 0,
    duration: 1000,
    easing: 'linear',
    delay: anime.stagger(550),
    begin: function(){
      aud.play()
    },
    complete: function() {
      router.push({name: 'home'})
    }
  })
}
</script>

<template>
  <div class="body-content z-10" v-auto-animate>
    <div class="page-wrapper">
      <div class="content-wrapper text-center">
        <div id="main_title" class="audBtn opacity-0">
          <h1 class="text-4xl playfair font-semibold">
            <span class="italic">Utusan Raja</span>
            <br>
            <span class="italic">Sehari</span>
          </h1>
        </div>

        <div id="content-child" class="font-semibold tracking-wide mt-2">
          <button id="audBtn" @click="playSong()" class="audBtn btn btn-primary">
            Buka Sekarang
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-wrapper{
  @apply h-[82vh] overflow-y-scroll flex items-center;
}
</style>
