
<template>
  <div id="content-child" class="bg-orange-100 py-4 mb-4 border border-orange-950 rounded-lg px-2">
    <p>Beras Kunyit Kami Taburi,</p>
    <p>Air Mawar Direnjis Wangi,</p>
    <p>Undangan Kami Setulus Hati,</p>
    <p>Harap Sudi Datang Merai.</p>
  </div>
</template>

<style scoped>
p{
  @apply font-semibold italic
}
</style>