<script setup>
import { onMounted} from 'vue';
import CountDown from './CountDown.vue'
import PantunCard from './PantunCard.vue'
import anime from 'animejs/lib/anime.es.js';

onMounted(()=>{
  setTimeout(() => {
    animateShit()
  }, 500)
})

function animateShit(){
  anime({
    targets: '.main-title',
    opacity: 1,
    duration: 1000,
    easing: 'linear',
    complete: function() {
      contentDrop()
    }
  })
}

function contentDrop(){
  anime({
    targets: '#content-child',
    opacity: 1,
    translateY: [-10, 0],
    easing: 'linear',
    duration: 950,
    delay: anime.stagger(700)
  })
}
</script>

<template>
  <div class="body-content z-10" v-auto-animate>
    <div class="page-wrapper">
      <div class="!justify-evenly content-wrapper text-center">
        <!-- <div class=""> -->
          <!-- title -->
          <!-- name -->
          <div class="main-title">
            <p class="!italic font-semibold text-5xl playfair">Dina</p>
            <p class="!italic font-semibold text-5xl playfair">&</p>
            <p class="!italic font-semibold text-5xl playfair">Izzat</p>
          </div>
          <!-- date -->
          <div id="content-child" class="mt-6">
            <div>
              <p class="font-semibold text-4xl">Sabtu</p>
              <div class="date">
                  <span>02</span>
                  <span>Nov</span>
                  <span>24</span>
              </div>
            </div>
          </div>
  
          <CountDown />
          <PantunCard class="my-4 w-full"></PantunCard>
  
          <div id="content-child" class="mt-10 flex justify-center">
            <router-link 
              :to="{name: 'about'}"
              class="animate-bounce btn btn-primary font-semibold text-xl"
            >
              >
            </router-link>
          </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-wrapper{
  @apply h-[82vh] overflow-y-scroll flex items-center;
}
</style>
