<script setup>
import { ref } from 'vue';


const showContact = ref(false)
const contactInfo = [
  {
    name: 'En. Shabudin',
    contact: '0194415361',
    ws: 'https://wa.link/jymafa'
  },
  {
    name: 'Pn. Zarina',
    contact: '0194475361',
    ws: 'https://wa.link/plfizq'
  },
  {
    name: 'En. Syafi',
    contact: '01164412419',
    ws: 'https://wa.link/tsryvy'
  },
  {
    name: 'En. Syakir',
    contact: '0184730350',
    ws: 'https://wa.link/ei66um'
  },
]
</script>

<template>
  <div id="content-child" class="w-full border rounded-lg border-orange-950 p-4" v-auto-animate>
    <div class="flex justify-between items-center">
      <p class="text-lg font-semibold tracking-wide">
        Alamat Majlis
      </p>
      <button class="btn btn-primary font-semibold" @click="showContact = !showContact">
        <span v-if="showContact == true">
          >
        </span>
        <span v-else>
          x
        </span>
      </button>
    </div>
    <div v-if="!showContact" class="text-left">
      <p class="mb-2">Dewan Kenangan, Menara MPSPK, Jalan Patani, Sungai Petani, 08000 Sungai Petani, Kedah</p>
      <p class="mb-2 text-sm">
        <span class="font-semibold">Waktu Jamuan : </span>
        11 pagi - 4 petang
      </p>
      <div class="flex justify-end">
        <a href="https://maps.app.goo.gl/QWPnfbvUqPwVbcQAA"
          class="btn btn-primary font-semibold tracking-wide"
        >
          Dapatkan Lokasi
        </a>
      </div>
    </div>
  </div>

  <div id="content-child" 
    class="w-full border rounded-lg border-orange-950 p-4 mt-2" v-auto-animate
  >
    <div class="flex justify-between items-center">
      <p class="text-lg font-semibold tracking-wide">
        Hubungi Keluarga 
      </p>
      <button class="btn btn-primary font-semibold" @click="showContact = !showContact">
        <span v-if="showContact == false">
          >
        </span>
        <span v-else>
          x
        </span>
      </button>
    </div>
    <div v-if="showContact" 
      v-auto-animate
      class="overflow-hidden" 
      :class="[{'max-h-64': showContact}, {'max-h-0': !showContact}]"
    >
      <div class="card-content">
        <div v-for="item in contactInfo" :key="item.name"
          class="bg-orange-200 w-full my-2 rounded-lg py-2 px-4 text-left flex justify-between items-center"
        >
          <p>{{ item.name }}</p>
          <div class="flex">
            <div class="flex mr-1">
              <a :href="'tel:+60' + item.contact"
                class="btn btn-primary"
              >
                <img src="@/assets/phone.png">
              </a>
            </div>
            <div class="flex">
              <a :href="item.ws"
                class="btn btn-primary"
              >
                <img src="@/assets/whatsapp.png">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

