
<template>
  <div class="border-card z-0">
    <img id="top-right" src="@/assets/corner-border.png" 
      class="w-[10rem] absolute top-0 -right-2"
    >
    <img id="bottom-right" src="@/assets/corner-border.png" 
      class="w-[10rem] absolute bottom-0 -right-2 rotate-90"
    >
    <img id="bottom-left" src="@/assets/corner-border.png" 
      class="w-[10rem] absolute bottom-0 -left-2 rotate-180"
    >
    <img id="top-left" src="@/assets/corner-border.png" 
      class="w-[10rem] absolute top-0 -left-2 -rotate-90"
    >
  </div>
  <div class="border-card z-0">
    <div id="particle-container">
      <div class="particle" v-for="i in 30" :key="i"></div>
    </div>
  </div>
</template>

